<template>
    <v-card>
        <v-card-title>
            {{ $t('downline-users') }}
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" :label="searchTitle" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table dense :headers="headers" :items="downline" :search="search" item-key="username" disable-initial-sort>
            <template v-slot:items="props">
                <td @click="onClick(props.item.id)">{{ props.item.username }}</td>
                <td class="">{{ props.item.createdAt|formatDateShort }}</td>
                <td class="">{{ props.item.firstName }} {{props.item.lastName}}</td>
                <td class="">{{ props.item.sponsorDepth - startLevel }}</td>
                <td class="w-50p"><country-flag :country="props.item.country.toUpperCase()" class="mr-3 opacity-8 medium-small-flag" /></td>
                <td class="w-50p"><a :href="'/#/binary/'+props.item.id"><font-awesome-icon :icon="['fas', 'network-wired']" /></a></td>
            </template>
            <v-alert v-slot:no-results :value="true" color="error" icon="warning">
                {{ $t('search-no-result', [search])}}
            </v-alert>
        </v-data-table>
    </v-card>
</template>

<script>
import {mapGetters} from "vuex";

import CountryFlag from "vue-country-flag";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons'

library.add(faNetworkWired);

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        CountryFlag,
    },
    props: [
        "downline",
        "startLevel",
    ],
    data() {
        return {
            search: "",
            headers: [
                {
                    text: this.$i18n.t("username"),
                    align: "left",
                    value: "username"
                },
                { text: this.$i18n.t("joined"), value: "userId" },
                { text: this.$i18n.t("name"), value: "name" },
                { text: this.$i18n.t("level"), value: "level" },
                { text: this.$i18n.t("country"), value: "country" },
                { text: "", value: "binary", sortable: false}
            ],
            searchTitle: this.$t('Search')
        };
    },
    methods: {
      onClick(value) {
          this.$emit('clicked', value)
      }
    },
    computed: {
        ...mapGetters(
            {users:'downline/users'}
        )
    }
};
</script>

<style>
    .flag-small {
        max-height: 48px;
        width: 100%;
    }

    .medium-small-flag {
        margin: -18px;
        transform: scale(0.4);
        -ms-transform: scale(0.4);
        -webkit-transform: scale(0.4);
        -moz-transform: scale(0.4);
    }
</style>