<template>
    <div>
        <div v-for="(itm, index) in upline" :key="itm.id" class="bc-btn">
            <v-icon v-if="index > 0" class="bc-ico">forward</v-icon>
            <v-btn text small @click="onClick(itm.id)" v-if="itm.username" class="text-original">{{itm.username}}</v-btn>
            <v-btn text small disabled v-else>...</v-btn>
        </div>


    </div>
</template>

<script>
    export default {
        props: ["upline"],
        data: () => ({
            items: [
                {
                    text: "Upupline",
                    disabled: false,
                    href: "breadcrumbs_dashboard"
                },
                {
                    text: "Upline",
                    disabled: false,
                    href: "breadcrumbs_link_1"
                },
                {
                    text: "User",
                    disabled: true,
                    href: "breadcrumbs_link_2"
                }
            ]
        }),
        methods: {
            onClick(value) {
                this.$emit('clicked', value)
            }
        }
    };
</script>

<style scoped>
.bc-ico {
    vertical-align: middle;
}

.text-original {
    text-transform: unset;
}
</style>
