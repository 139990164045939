<template>
    <div class="card-shadow-primary card-border mb-0 card he-100">
                    <div class="dropdown-menu-header">
                        <div class="dropdown-menu-header-inner bg-primary p-2">
                            <div class="menu-header-content">
                                <div>
                                    <h5 class="menu-header-title" v-if="user.email">{{ user.firstName }} {{ user.lastName }}</h5>
                                    <h5 class="menu-header-title" v-else>{{user.username}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="">
                            <ul class="list-group list-group-flush">

                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-user text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('username')}}:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ user.username }}</div>
                                            </div>

                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-date text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('member-since') }}:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ user.createdAt|formatDateShort }}</div>
                                            </div>

                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0 align-center">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-mail text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('email') }}:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ user.email }}</div>
                                            </div>

                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-medal text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('rank') }}:</div>
                                            </div>

                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ getRankName(user.totals.rank) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-box2 text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('package') }}:</div>
                                            </div>

                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ user.activePackage }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-global text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('pool-status') }}:</div>
                                            </div>

                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ user.poolStatus|pool }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1" v-if="user.totals.tokens && isAdmin">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-wallet text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('tokens.available') }}:</div>
                                            </div>

                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ user.totals.tokens|formatHtl }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1" v-if="user.totals.sharesA && isAdmin">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-news-paper text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('tokens.sharesA') }}:</div>
                                            </div>

                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ user.totals.sharesA|formatHtl  }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1" v-if="(user.totals.shares || user.totals.held) && isAdmin">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-news-paper text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('tokens.sharesB') }}:</div>
                                            </div>

                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ (user.totals.shares + user.totals.held)|formatHtl }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                    </div>
                </div>
</template>


<script>

    import { library } from "@fortawesome/fontawesome-svg-core";
    import {
        faArrowRight,
        faArrowLeft,
        faAngleUp,
        faDotCircle,
        faAngleDown
    } from "@fortawesome/free-solid-svg-icons";
    import { mapGetters } from 'vuex'

    library.add(faArrowRight, faAngleUp, faArrowLeft, faDotCircle, faAngleDown);

    export default {
        props: [
            "user"
        ],
        data: () => ({

            fill: { gradient: ["var(--primary)"] },

        }),

        methods: {
            getRankImg(rank) {
                return require('../../assets/images/ranks/'+rank+'.png')
            },
            getRankName(val) {
                const rankName = [
                    this.$i18n.t('rank-0'),
                    this.$i18n.t('rank-1'),
                    this.$i18n.t('rank-2'),
                    this.$i18n.t('rank-3'),
                    this.$i18n.t('rank-4'),
                    this.$i18n.t('rank-5'),
                    this.$i18n.t('rank-6'),
                    this.$i18n.t('rank-7'),
                    this.$i18n.t('rank-8'),
                    this.$i18n.t('rank-9'),
                    this.$i18n.t('rank-10'),
                    this.$i18n.t('rank-11'),
                ];
                return rankName[val];
            }
        },
        computed: {
            ...mapGetters( {
                isAdmin: 'user/isAdmin'
            }),
            profile() {
                return this.$store.state.profile;
            },
            kyc() {
                if (this.$store.state.profile.kyc === 0) return "unconfirmed";
                else if (this.$store.state.profile.kyc === 1) return "pending";
                else if (this.$store.state.profile.kyc === 2) return "confirmed";
                else return "rejected";
            }
        }
    };
</script>

<style>
    .widget-heading > span {
        font-size: 1rem!important;
        font-weight: 600;
        padding-left: 0.7rem;
    }
    .confirmed {
        color: #0ba360;
    }

    .unconfirmed {
        color: red;
    }

    .pending {
        color: #1e3c72;
    }


</style>