<template>
    <div v-if="page">
        <h2>{{ $t('downline-title')}}</h2>
        <div class="row">
            <binary-breadcrumbs class="col-md-12 breadcrumb-btns" :upline="root.upline" @clicked="onClick" />
        </div>
        <div class="row">
            <div class="col-md-6 mb-3">
                <profile-box-tokens class="" :user.sync="root"  />
            </div>
            <div class="col-md-6 mb-3">
                <volume-stats class="" :user.sync="root.userBinaryTotals" :totals.sync="root.totals" :title="statsTitle" :user-id.sync="root.id" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <downline class="mb-3 w-100" :downline.sync="root.downline" :start-level.sync="root.sponsorDepth" @clicked="onClick" />
            </div>

        </div>
    </div>

</template>

<script>
import BinaryBreadcrumbs from "@/components/Tree/BinaryBreadcrumbs";
import ProfileBoxTokens from "@/components/Tree/ProfileBoxTokens";
import VolumeStats from "@/components/Tree/VolumeStats";
import Downline from "@/components/Tree/Downline";
import gql from "graphql-tag";
export default {
    name: "DownlinePage",
    components: {VolumeStats, ProfileBoxTokens, BinaryBreadcrumbs, Downline},
    data() {
        return {
            page: null,
            root: null,
            statsTitle: this.$t('My Downline Data')
        }
    },
    methods: {
        onClick(value) {
            if (value === this.root.id) return;
            this.$apollo.query(
                {
                    query: gql`query($id:ID!){user(id:$id){id,sponsorDepth,createdAt,username,firstName,lastName,email,activePackage,poolStatus,canViewDownline,totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight,downLeft,downRight},upline(max:3){id,more,username},
                    downline{id,createdAt,sponsorDepth,username,firstName,lastName,country}}}`,
                    variables: {
                        id: value
                    },
                }
            ).then(data => {
                this.root = data.data.user
                this.root.downline.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1)
                this.root.upline.push(this.root)
                this.$forceUpdate()
            })
        }
    },
    mounted() {
        if (this.$route.params.userId) {
            this.$apollo.query(
                {
                    query: gql`query($id:ID!){user(id:$id){id,sponsorDepth,createdAt,username,firstName,lastName,email,activePackage,poolStatus,canViewDownline,totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight,downLeft,downRight},upline(max:3){id,more,username},
                    downline{id,createdAt,sponsorDepth,username,firstName,lastName,country}}}`,
                    variables: {
                        id: this.$route.params.userId
                    },
                }
            ).then(data => {
                this.root = data.data.user
                this.root.downline.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1)
                this.root.upline.push(this.root)
                this.$forceUpdate()
            })
        }
    },
    computed: {
    },
    apollo: {
        page: {
            query: gql`query{
                viewer{
                    id,createdAt,sponsorDepth,username,firstName,lastName,email,country,activePackage,poolStatus,
                    totals{rank,activeVolumeLeft,activeVolumeRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight,tokens,activeFiatLeft,activeFiatRight},
                    userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight},upline(max:3){id,more,username},
                    downline{id,createdAt,sponsorDepth,username,firstName,lastName,country}
                },
                viewerIsAdmin
            }`,
            update: data => data,
            result({data}) {
                if (this.$route.params.userId) return;
                if (data) {
                    this.root = data.viewer
                    this.root.downline.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1)
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewer.totals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

.breadcrumb-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0.25rem;
}
</style>