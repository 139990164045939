<template>
                <div class="card-shadow-primary card-border  card he-100 mb-0">
                    <div class="dropdown-menu-header">
                        <div class="dropdown-menu-header-inner bg-asteroid p-2">
                            <div class="menu-header-content">
                                <div>
                                    <h5 class="menu-header-title">{{title}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-users text-muted fsize-3 mirror-icon"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('left-users')}}:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ user.countLeft }} ({{user.countNewLeft}} new)</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-users text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('right-users')}}:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ user.countRight }} ({{user.countNewRight}} new)</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-angle-left-circle text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('volume-left')}} HTL:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ totals.unpaidVolumeLeft|formatHtl }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-angle-left-circle text-muted fsize-3 mirror-icon"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('volume-right')}} HTL:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ totals.unpaidVolumeRight|formatHtl }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-right-arrow mirror-icon text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('lifetime-left')}} HTL:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ totals.activeVolumeLeft|formatHtl }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-right-arrow text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('lifetime-right')}} HTL:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ totals.activeVolumeRight|formatHtl }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-right-arrow mirror-icon text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('lifetime-left')}} PV.:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ totals.activeFiatLeft|formatEur }}</div><!-- -->
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item py-1">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left center-elem mr-2 row-icon">
                                                <i class="pe-7s-right-arrow text-muted fsize-3"></i>
                                            </div>
                                            <div class="widget-content-left field-min-width text-right mr-1">
                                                <div class="widget-heading fsize-1">{{ $t('lifetime-right')}} PV.:</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="font-weight-bolder fsize-1">{{ totals.activeFiatRight|formatEur }}</div><!-- -->
                                            </div>
                                        </div>
                                        
                                    </div>
                                </li>


                                <li class="list-group-item py-1">
                                    <a class="btn-transition-text btn-transition btn-transition-alt btn btn-outline-primary mx-1 py-0"
                                       :href="'/#/downline/'+userId">
                                        {{$t('to-downline')}}
                                    </a>
                                    <a type="button"
                                       class="btn-transition-text btn-transition btn-transition btn btn-outline-success mx-1 py-0"
                                       :href="'/#/binary/'+userId">
                                        {{$t('to-binary')}}
                                    </a>
                                </li>
                            </ul>
                    </div>
                </div>
</template>
<script>
    import { library } from "@fortawesome/fontawesome-svg-core";
    import {
        faArrowRight,
        faArrowLeft,
        faAngleUp,
        faDotCircle,
        faAngleDown
    } from "@fortawesome/free-solid-svg-icons";
    import { mapGetters } from 'vuex'
    library.add(faArrowRight, faAngleUp, faArrowLeft, faDotCircle, faAngleDown);
    export default {
        props: [
            "user",
            "totals",
            "title",
            "userId"
        ],
        data: () => ({
            fill: { gradient: ["var(--primary)"] },
        }),
        methods: {
            getRankImg(rank) {
                return require('../../assets/images/ranks/'+rank+'.png')
            }
        },
        computed: {
            ...mapGetters({
                isAdmin: 'user/isAdmin'
    }),
            profile() {
                return this.$store.state.profile;
            },
            kyc() {
                if (this.$store.state.profile.kyc === 0) return "unconfirmed";
                else if (this.$store.state.profile.kyc === 1) return "pending";
                else if (this.$store.state.profile.kyc === 2) return "confirmed";
                else return "rejected";
            }
        }
    };
</script>